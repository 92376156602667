
import React from "react";
import * as styles from "./matching-offers-section.module.scss";

import Scheduled from "../components/scheduled"

const MatchingOffersSection = (props) => {
  // const { id } = props;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <MatchingOffer
          start="november 8, 2021 12:01am"
          end="november 12, 2021 11:59pm"
          displaydate="All This Week"
          logo="regence"
          headline="Give a toy, and Regence will too!"
          disclaimer="Up to $5,000"
        />
        <MatchingOffer
          start="november 29, 2021 12:01am"
          end="december 3, 2021"
          displaydate="All This Week"
          logo="perfect-look"
          headline="Give a toy, and Perfect Look will too!"
          disclaimer="Up to $5,000"
        />
      </div>
    </div>
  );
};

const MatchingOffer = (props) => {

  // optional: start (now), end (never), displaydate, logo, headline, details, disclaimer
  // if used as a surrounding tag, other elements won't appear

  const { start, end, displaydate, logo, headline, details, disclaimer } = props
  const logoPath = "/matching-logos/"

  return (
    <Scheduled start={start} end={end} >
      <div className={styles.offer}>{(typeof (props.children) != "undefined") ? props.children : (
        <div className={styles.formatted}>
          {headline && <h1 className={styles.headline}>{headline}</h1>}
          {displaydate && <h2 className={styles.displayDate}>{displaydate}</h2>}
          {logo && <div className={styles.logo}>
            <img src={logoPath + logo + ".png"} />
          </div>}
          
          {details && <p className={styles.details}>{details}</p>}
          {disclaimer && <p className={styles.disclaimer}>{disclaimer}</p>}
        </div>
      )}
      </div>
    </Scheduled>
    
  )

}
MatchingOffersSection.MatchingOffer = MatchingOffer;

export default MatchingOffersSection;
