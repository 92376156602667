import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import URLs from "../../static/data/urls.json";
import Seo from "../components/seo";
import ClientSection from "../sections/client-section";
import EventsSection from "../sections/events-section";
import HowToDonateSection from "../sections/how-to-donate-section";
import MatchingSection from "../sections/matching-offers-section";
import ProgressSection from "../sections/progress-section";
import TextSection from "../sections/text-section";
import VideoSection from "../sections/video-section";
import Layout from "./layouts/layout";
import * as queryString from "query-string";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout home={true} mdxType="Layout">
  <Seo title="Home" mdxType="Seo" />
      <ClientSection mdxType="ClientSection" />
      <MatchingSection mdxType="MatchingSection" />
      <HowToDonateSection mdxType="HowToDonateSection" />
      <VideoSection videos={[{
        file: "regence-60.mp4"
      }, {
        file: "iq-toy-bot-30.mp4"
      }, {
        file: "fred-meyer-30.mp4"
      }, {
        file: "toyota-duplicator-30.mp4"
      }, {
        file: "iq-surprise-30.mp4"
      }, {
        file: "toyota-surprise-30.mp4"
      }]} mdxType="VideoSection" />
  <EventsSection url={URLs.events_url} mdxType="EventsSection" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      