import * as chrono from "chrono-node";
import React from "react";
import ExternalLink from "../components/external-link";
import * as styles from "./event.module.scss";

// Key must be sequential number for animation to work correctly!

const Event = (props) => {

  console.log("----- BEGIN EVENT -----")
  console.log(props)
  const { location, date, startTime, endTime, endDate, fullAddress, logo, id } = props;
  console.log(`endDate: ${endDate}`)
  var rightNow = chrono.parseDate("now");
  const displayTime = smartTime(date, endDate, startTime, endTime, rightNow);
  const displayDate = smartDate(date, rightNow);
  const displayEndDate = smartDate(endDate, rightNow);
  console.log("DISPLAY DATE " + displayDate)
  console.log("DISPLAY END DATE " + displayEndDate)

  const todayClass = displayDate === "Today" ? styles.today : "";
console.log(`TODAY CLASS: ${todayClass}`)
  return (
    <>
      {displayTime && (
        <div key={id} className={`${styles.container} ${todayClass}`}>
          <div className={styles.wrapper}>
            <ExternalLink
              href={
                "http://maps.google.com/?q=" +
                encodeURIComponent(fullAddress).replace(/%20/g, "+")
              }
            >
              {" "}
              <div className={styles.text}>
              <h1>{displayDate}</h1>
                {displayEndDate && (<><p>through</p>
                  <h1 className={styles.endDate}>{displayEndDate}</h1></>)
                }
                <h2>{displayTime}</h2>
                <div className={styles.logos}>
                  <div className={styles.logo}>
                    <img src={"/event-logos/" + logo + ".png"} alt="" />
                  </div>
                </div>
                <p>{location}</p>
                <p className={styles.address}>{fullAddress}</p>
                <div className={`${styles.details} ${todayClass}`}></div>
              </div>
            </ExternalLink>
          </div>
        </div>
      )}
    </>
  );

};
export default Event;

function daysBetween(date1, date2) {
  // calculates days between date1 and date2
  // https://www.htmlgoodies.com/html5/javascript/calculating-the-difference-between-two-dates-in-javascript.html


  if (!(date1 && date2)) {
    return null;
  }

  //Get 1 day in milliseconds
  var one_day = 1000 * 60 * 60 * 24;

  // Convert both dates to milliseconds
  var date1_ms = date1.getTime();
  var date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  var difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / one_day);
}

function smartDate(startDate, now) {
  // Return a phrase for the relationship between the current date (or optional (now) date) and a given date range: startDate and (optional) endDate

  if (!(startDate)) { return null; }

  var rightNow = now || chrono.parseDate("now");

  var daysOfTheWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var monthsOfTheYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var thenDate = chrono.parseDate(startDate);

  var daysToStartDate = daysBetween(rightNow, thenDate);
  var returnVal;
  if (daysToStartDate < 1) {
    returnVal = "Today";
  } else if (daysToStartDate === 1) {
    returnVal = "Tomorrow";
  } else if (daysToStartDate < 7) {
    returnVal = daysOfTheWeek[thenDate.getDay()];
  } else {
    returnVal =
      daysOfTheWeek[thenDate.getDay()] +
      ", " +
      monthsOfTheYear[thenDate.getMonth()] +
      " " +
      thenDate.getDate();
  }

  return returnVal;
}

function smartTime(date, end_date, start_time, end_time, now) {
  var rightNow = now || chrono.parseDate("now");

  var startDate = date;
  var endDate = end_date || date;

  var startTime = chrono.parseDate(startDate + " " + start_time);
  var endTime = chrono.parseDate(endDate + " " + end_time);
  var returnVal;

  if (rightNow < startTime) {
    returnVal = start_time + " - " + end_time;
  } else if (rightNow < endTime) {
    returnVal = "Until " + end_time;
  } else {
    returnVal = false;
  }
  return returnVal;
}
