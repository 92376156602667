import * as chrono from "chrono-node";
import fetch from "node-fetch";
import React, { Component } from "react";
import Event from "./event";
import * as styles from "./events-section.module.scss";


class EventsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      url: props.url,
    };
  }

  UNSAFE_componentWillMount() {
    fetch(this.state.url)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        this.setState({ events: resultData });
      });
  }

  render() {
    const events = eventFilter(this.state.events);
    console.log("===== EVENT SECTION =====")
    console.log(events)

    return (
      <>
        {events && (
          <div className={styles.container}>
            <div className={styles.wrapper}>
            <div
            
            >
              <h1
                style={
                  events.length > 0 ? { display: `block` } : { display: `none` }
                }
              >
                Collection Events
              </h1>
              
            </div>
            <div id={"events"} className={styles.events}>
              {events.map((event, index) => (
                <Event
                  key={index}
                  name={event.location}
                  date={event.date}
                  endDate={event.end_date}
                  startTime={event.start_time}
                  endTime={event.end_time}
                  logo={event.logo}
                  fullAddress={
                    event.location + ", " + event.address + ", " + event.city + ", " + event.state
                  }
                  location={event.city}
                ></Event>
              ))}
            </div>
          </div>
          </div>
        )}
      </>
    );
  }
}
export default EventsSection;

function eventFilter(events) {
  return events.filter((event) => {
    // var start = chrono.parseDate(event.date + " " + event.starttime);
    // var now = chrono.parseDate("11/20/2021 2:00pm");
    var now = chrono.parseDate("now");
    // console.log(event.date + " " + event.end_time);
    var end = chrono.parseDate(event.end_date || event.date + " " + event.end_time);
    
    // console.log("----------------");
    // console.log(start);
    console.log("---");
    console.log("NOW: " + now);
    console.log("END: " + end);
    console.log(end > now);
     
    return end > now;
  });
}
