import fetch from "node-fetch";
import React, { Component } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TrackVisibility from "react-on-screen";
import * as styles from "./progress-section.module.scss";

class ProgressSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: "12,000",
      term: "kids helped",
      current_number: 0,
      value: 0,
    };
  }

  componentDidMount() {
    const url =
      "https://kgw-sales-partners.s3.us-west-2.amazonaws.com/ssd-total.json" + cacheFix();
    fetch(url)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        // var temp = this.state.lists
        // res = resultData
        console.log("RESSULTS");
        console.log(resultData[0]);
        const res = resultData[0];
        this.setState({
          goal: res.goal_number,
          value: res.percentage,
          current_number: res.current_number,
          term: res.term,
        });
      });
    return null;
  }

  render() {
    return (
      <section className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.text}>
            <h1>Help us reach our goal!</h1>
            <h2>School supplies for {this.state.goal} local kids!</h2>
            <p>
              Every child deserves school supplies and the right to a quality
              education regardless of their family’s income or background.
            </p>
          </div>
          <div className={styles.ring}>
            <TrackVisibility once>
              {({ isVisible }) => {
                const percentage = isVisible ? this.state.value : 0;
                return (
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={buildStyles({
                      strokeLinecap: "butt",
                      textColor: "#201c1d",
                      pathColor: "#201c1d",
                      trailColor: "#f9a31a",
                      pathTransitionDuration: 3.15,
                    })}
                    strokeWidth={10}
                  />
                );
              }}
            </TrackVisibility>
            <div className={styles.soFar}>
              <p>
                We’ve raised enough school supplies to support{" "}
                <strong>{this.state.current_number} kids!</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ProgressSection;

function cacheFix() {
  return "?v=" + new Date().getTime(); 
}