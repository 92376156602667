import * as chrono from "chrono-node"
import React from "react"

// requires: start, end
// optional: now (simulate a date/time)

class Scheduled extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      start: chrono.parseDate(props.start || "now"),
      end: chrono.parseDate(props.end),
      now: chrono.parseDate(props.now || "now"),
      hide: props.hide || false,
      children: props.children,
      visible: false,
    }
  }

  componentDidMount() {
    this.updateVisibility()
  }

  updateVisibility = () => {
    const rightNow = chrono.parseDate("now")
    const isScheduled =
      this.state.start <= rightNow && this.state.end > rightNow
    this.setState({
      visible: isScheduled ? !this.state.hide : this.state.hide,
    })
  }

  render() {
    return <div>{this.state.visible && <div>{this.state.children}</div>}</div>
  }
}
export default Scheduled
