// extracted by mini-css-extract-plugin
export var container = "event-module--container--38c3o";
export var wrapper = "event-module--wrapper--2EWZR";
export var address = "event-module--address--KV30-";
export var logos = "event-module--logos--18MJQ";
export var logo = "event-module--logo--2n8CP";
export var text = "event-module--text--2Q9_O";
export var details = "event-module--details--1YIrG";
export var today = "event-module--today--CxzAw";
export var day = "event-module--day--2WBk5";
export var time = "event-module--time--3QHNo";