import React from "react";
import * as styles from "./client-box.module.scss";

const ClientBox = (props) => {
  const { name, logo, text, url, last, children } = props;

  

  return (
    <a
      href={url}
      key={name}
      className={styles.clientBoxWrapper + " " + (last ? styles.lastItem : "")}
    >
      <div
        className={styles.clientLogo}
        data-sal="zoom-in"
        data-sal-duration="1000"
        data-sal-delay="100"
        data-sal-easing="ease"
      >
        <img src={logo} alt={name} />
      </div>
      <div className={styles.clientText}>{children || text}</div>
    </a>
  );
};
export default ClientBox;
