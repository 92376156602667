import React from "react";
import VideoPlaylist from "../components/video-playlist";
import * as containerStyles from "./video-section.module.scss";
import { FaSnowflake } from "react-icons/fa";

const VideoSection = (props) => {
  const { videos, first } = props;
  // console.log(videos);
  return (
    <section className={containerStyles.section}>
      <div className={containerStyles.snowflakes}><FaSnowflake /><FaSnowflake /><FaSnowflake /></div>
      <VideoPlaylist
        id="home-page-video"
        path="https://media.kgwcreative.com/great-toy-drive/"
        videos={videos}
        first={first}
      />
      <div className={containerStyles.snowflakes}><FaSnowflake /><FaSnowflake /><FaSnowflake /></div>
    </section>
  );
};

export default VideoSection;

