import React from "react";
// import KGWLogo from "../images/kgw-400x160.png";
import RegenceLogo from "../images/regence/logo-400.png";
import IQLogo from "../images/iq/logo-400.png";
import ToyotaLogo from "../images/toyota/logo-400.png";
import FredMeyerLogo from "../images/fred-meyer/logo-400.png";
import ClientBox from "./client-box";
import * as styles from "./client-section.module.scss";

const ClientSection = (props) => {
  // const { id } = props;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
      <h1>Sponsored By:</h1>
      <div className={styles.clientBoxes}>
        <div>
          <ClientBox
            name="Regence"
            logo={RegenceLogo}
            text="Matching cash donations the week of November 8."
            url="https://regence.com"
          />
        </div>
        <div>
          <ClientBox
            name="iQ"
            logo={IQLogo}
            url="/iq"
            >
            Accepting cash donations{" "}
                <a href={""}>online</a> and toy donations at a{" "}
                <a href="/iq">location near you.</a>
            </ClientBox>
        </div>
        <div>
          <ClientBox
            name="Toyota"
            logo={ToyotaLogo}
            url="/toyota"
            last={true}
            >
            Accepting toy donations at a <a href="/toyota">dealership near you.</a>
          </ClientBox>
        </div>
        <div>
          <ClientBox
            name="Fred Meyer"
            logo={FredMeyerLogo}
            url="/fred-meyer"
            last={true}
            >
            Buy and drop off toys at a <a href="/fred-meyer">location near you</a>.<br />Buy toys online at <a href="https://www.fredmeyer.com/d/toys">FredMeyer.com</a>
          </ClientBox>
        </div>
        </div>
        </div>
    </div>
  );
};
export default ClientSection;
