import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import URLs from "../../static/data/urls.json";
// import ChalkboardSection from "../sections//chalkboard-section.js";
import cashIcon from "../images/icons/cash-icon.png";
import mapLocatorIcon from "../images/icons/map-locator.png";
// import { useStaticQuery, graphql } from "gatsby";
import storeIcon from "../images/icons/package-icon.png";
import ExternalLink from "../components/external-link";
import * as styles from "./how-to-donate-section.module.scss";
// import ToyOfTheDay from "./toy-of-the-day-section";


const HowToDonate = (props) => {
  const theLinks = [
    {
      caption: "Donate money online",
      url: URLs.donate_cash_url,
      image: cashIcon,
    },
    {
      caption: "Find your nearest Toy Drop",
      url: URLs.drop_off_url,
      image: mapLocatorIcon,
    },
  ];

  const { site, backgroundYellow } = useStaticQuery(graphql`
    query HowToDonateQuery {
      site {
        siteMetadata {
          title
        }
      }
      backgroundYellow: file(
        relativePath: { eq: "campaign-theme/campaign-texture.webp" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const { title } = site.siteMetadata;



  return (
    <div id={"donate"} className={styles.container + " card"} style={{
            backgroundImage: `url( ${backgroundYellow.childImageSharp.fluid.src} )`,
          }}>
      <h1>Donate a toy today!</h1>
      <div className={styles.buttonGroup}>
        {theLinks.map((link, index) => (
          <div key={index} className={styles.button}>
            <ExternalLink href={link.url}>
              <div className={styles.buttonText}>
                <h2>{link.caption}</h2>
              </div>
              <div className={styles.buttonImage}>
                <img src={link.image} alt={link.caption} />
              </div>
            </ExternalLink>
          </div>
        ))}
      </div>
      // ToyOfTheDay

    </div>
  );
};

export default HowToDonate;
