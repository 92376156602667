// Text Section
//
// Creates a standard content area with white background
// classes parameter can add universal classes from layout css

import React from "react";
import * as styles from "./text-section.module.scss";

const TextSection = (props) => {
  const { maxwidth, classes, children } = props;

  return (
    <div className={`${styles.container} ${classes}`}>
      <section style={{ maxWidth: maxwidth || "100%" }}>{children}</section>
    </div>
  );
};
export default TextSection;
