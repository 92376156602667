import React from "react";
import * as styles from "./video-playlist.module.scss";

/*

Accepts the following properties:

required:
videos: a JSON list of videos, in this format:
    {
      file: "filename.mp4", 
      title: "Title of the Video", 
      abstract: "Longer description of the video."
    }
Only the file property is required.
id: unique css id for video tag

optional:
first: index of the video to move to the front of the list (default 0)
path: path to prepend to each filename (default "")
picker: provide a list of movies below the player, boolean (default false)

poster image is in same location as movie file, extension .jpg instead of .mp4
ex: filename.jpg

*/

class VideoPlaylist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: makeThisVideoFirst(props.videos, props.first),
      path: props.path || "",
      picker: props.picker,
      id: props.id,
      videoId: props.id + "-video",
      currentVideo: 0,
    };
    // console.log(this.state.videos);
  }

  componentDidMount() {
    this.playVideo(0, { muted: true });
  }

  handleClick(nn) {
    this.playVideo(nn, { muted: false });
  }

  playVideo(index, args) {
    var videoPlayer = document.getElementById(this.state.videoId);
    var videoCount = index;

    if (this.state.videos) {
      videoPlayer.muted = args.muted;
      videoPlayer.src = this.state.path + this.state.videos[videoCount].file + cacheFix();
      this.setState({
        currentVideo: videoCount,
      });
      videoPlayer.onended = () => {
        console.log("Changing video");
        // keeps the video from glitching
        videoPlayer.height = videoPlayer.offsetHeight;
        videoPlayer.width = videoPlayer.offsetWidth;

        videoCount++;
        if (videoCount >= this.state.videos.length) {
          videoCount = 0;
        }
        videoPlayer.pause();
        // console.log("Setting video");
        // console.log(this.state.path + this.state.videos[videoCount].file);
        videoPlayer.setAttribute(
          "src",
          this.state.path + this.state.videos[videoCount].file + cacheFix()
        );
        videoPlayer.load();
        videoPlayer.play();
        this.setState({
          currentVideo: videoCount,
        });
      };
    }
  }

  render() {
    return (
      <div className={`${styles.wrapper} ${this.state.id}`}>
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: `
        <video
          muted
          autoplay
          playsinline
          controls
          type="video/mp4"
          id="${this.state.videoId}"
          class="${styles.video}"
          poster="${
            this.state.path + this.state.videos[0].file.replace(".mp4", ".jpg")
          }" + + cacheFix()
        />,
      `,
            }}
          ></div>
        </div>
        {this.state.picker ? (
          <div className={styles.picker}>
            {this.state.videos.map((video, i) => (
              <div
                role="button"
                tabIndex={0}
                key={i}
                className={
                  `${styles.pickerItem} ` +
                  `${this.state.currentVideo} ` +
                  (this.state.currentVideo === i ? `${styles.active}` : ``)
                }
                onClick={() => this.handleClick(i)}
                onKeyDown={this.handleClick}
              >
                <div className={styles.thumbnail}>
                  <img
                    src={
                      this.state.path +
                      this.state.videos[i].file.replace(".mp4", ".jpg")
                    }
                    alt={video.title || video.file}
                  />
                </div>
                <div className={styles.info}>
                  <h2>{video.title || video.file}</h2>
                  <p style={video.abstract ? {} : { display: "none" }}>
                    {video.abstract}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default VideoPlaylist;

function makeThisVideoFirst(arr, f) {
  arr.forEach(function (item, i) {
    if (i === f) {
      arr.splice(i, 1);
      arr.unshift(item);
    }
  });
  // console.log(arr);
  return arr;
}

function cacheFix() {
  return "?v=" + new Date().getTime(); 
}